/* You can add global styles to this file, and also import other style files */

/* Configuracion de las propiedades del spinner */
/* -------------------------------------------- */

#config-spinner{
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.configSpinner {
  color:  rgba(0, 0, 0, 0.363);
  width: 3rem;
  height: 3rem;
  /* margin-top: 50%; */
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.avatar_mask{
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  overflow: hidden;
}
.avatar_mask img {
  width: 40px;
  height: 40px;
}
